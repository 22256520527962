@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
========================

===   Global Styles  ===

========================
*/

body {
    background-image: linear-gradient(180deg,#f5f5f5,rgba(248,249,250,0) 89%);
}

img {
    overflow: hidden;
}

.box {
    min-height: 100vh;
}

#link:after {
    display: inline-block;
  -webkit-transform: scale(0.5, 1); /* Safari and Chrome */
  -moz-transform: scale(0.5, 1); /* Firefox */
  -ms-transform: scale(0.5, 1); /* IE 9 */
  -o-transform: scale(0.5, 1); /* Opera */
  transform: scale(0.5, 1); /* W3C */
  content: ">";
  position: relative;
  margin-left: 4px;
}

a {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.hero-image {
    background-image: url(./assets/Background.svg);
    background-color: #cccccc;
    height: 100vh;
    background-repeat: no-repeat;
	background-position: center;
    background-size: cover;
    position: relative;
}

.text {
    font-family: 'Roboto', sans-serif;
    color: white;
}

